import React from 'react';
import { NavLink } from 'react-router-dom';
import { setIframeHeight } from 'helpers/utils';

const NotFound = () => {
  return (
    <div className='not-found' onLoad={() => setIframeHeight()}>
      <h1 className='text-center'>
        <span>404</span> - page not found
      </h1>
      <NavLink to='/'>go to home page</NavLink>
    </div>
  );
};

export default NotFound;
