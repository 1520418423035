import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Banner from 'components/Banner';

const TwoBlocks = ({ img, content, content_position }) => {
  return (
    img &&
    content && (
      <div className={`twoBlocks ${content_position}`}>
        <div className='image'>
          <LazyLoadImage src={img?.src} alt={img?.alt} effect='blur' />
        </div>
        <div className='text'>
          {content?.banner ? (
            <Banner {...content} />
          ) : (
            <div className='pigeon-gray'>
              {content && (
                <p>
                  {content?.title && <span className='title'>{content?.title}</span>}
                  {content?.copy && <span className='copy'>{content?.copy}</span>}
                </p>
              )}
            </div>
          )}
        </div>
      </div>
    )
  );
};

export default TwoBlocks;
