import React from 'react';
import { Row } from 'react-bootstrap';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const PalletColors = ({ colors }) => (
  <Row className='pallet-colors w-100 mx-0 px-md-4 py-md-5 p-2'>
    {colors?.map((color, index) => {
      return (
        <div key={index} className='color'>
          <a href={color?.redirect_url} rel='noreferrer' target='_blank' className='hexagon'>
            {color?.source ? (
              <LazyLoadImage src={color.source} alt={color.name} />
            ) : (
              <div className='hex'>{color?.hex}</div>
            )}
          </a>
        </div>
      );
    })}
  </Row>
);

export default PalletColors;
