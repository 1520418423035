import React, { useContext } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import Spinner from 'react-bootstrap/Spinner';
import { QuestionContext } from 'context/providers/QuestionProvider';

const StepsPanel = () => {
  const { questions, activeQuestion, loading, selectedQuestions, setNextPrevQuestion } = useContext(QuestionContext);
  const { title, description } = activeQuestion;
  const index = activeQuestion?.id + 1;

  return (
    <Container className='steps-panel'>
      <h3 className='steps-panel-title'>Question {index}</h3>
      <Row className='content'>
        <Col xl={9} xs={12} className='px-0'>
          {title && <h1 className='title' dangerouslySetInnerHTML={{ __html: `${title}` }} />}
          {description && <h5 className='description'>{description}</h5>}
        </Col>
        <Col xl={3} xs={12} className='group px-0'>
          {index > 0 && (
            <Button
              className='prev main-button'
              onClick={() => setNextPrevQuestion('prev')}
              disabled={loading}>
              Back
            </Button>
          )}
          <Button
            className='next main-button'
            onClick={() => setNextPrevQuestion('next')}
            disabled={
              (selectedQuestions?.length !== questions?.length && index === 12) ||
              !selectedQuestions.includes(index - 1) ||
              loading
                ? true
                : false
            }>
            {index === 12 ? 'Submit' : 'Continue'}
            {loading && <Spinner animation='border' />}
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

export default StepsPanel;
