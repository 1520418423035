import React, { useContext, useEffect, useState } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import Spinner from 'react-bootstrap/Spinner';
import { QuestionContext } from 'context/providers/QuestionProvider';
import Intro from 'containers/Intro';
import Question from 'containers/Question';
import PartnersEmail from 'containers/PartnersEmail';
import ThankYou from 'containers/ThankYou';
import Result from 'containers/Result';
import NotFound from 'containers/NotFound';
import Banner from 'components/Banner';
import './assets/sass/styles.scss';
import TagManager from 'react-gtm-module';

function App() {
  const location = useLocation();
  const { validateQuizzId } = useContext(QuestionContext);
  const [loadingPage, setLoadingPage] = useState(true);

  useEffect(() => {
    TagManager.initialize({
      gtmId: 'GTM-NF2GXBN'
    });

    async function validate() {
      await validateQuizzId();
      setTimeout(() => {
        setLoadingPage(false);
      }, 100);
    }
    validate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {location?.pathname !== '/' && location?.pathname !== '/result' && <Banner />}
      {loadingPage && (
        <div className='loader'>
          <Spinner animation='border' />
        </div>
      )}
      <Routes>
        <Route path='/' element={<Intro />} />
        <Route path='/question' element={<Question />} />
        <Route path='/partners-email' element={<PartnersEmail />} />
        <Route path='/thank-you' element={<ThankYou />} />
        <Route path='/result' element={<Result />} />
        <Route path='*' element={<NotFound />} />
      </Routes>
    </>
  );
}

export default App;
