import React from 'react';
import { NavLink } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { setIframeHeight } from 'helpers/utils';
import coupleMedia from 'assets/img/thankYou/shutterstock_1434688232.png';

const ThankYou = () => {
  return (
    <div
      className='thanks-message partners-email'
      onLoad={() => {
        setIframeHeight();
      }}>
      <Row className='status-bar w-100 px-0 mx-0' />
      <Container className='partners-email-content'>
        <Row>
          <Col xs={12} md={6} className='px-0'>
            <LazyLoadImage src={coupleMedia} alt='couple' className='img-fluid' />
          </Col>
          <Col xs={12} xl={5} md={6} className='px-0'>
            <div className='email-form'>
              <h1 className='title'>Thanks for taking the quiz!</h1>
              <p className='description'>
                Our Color Influencers are now hard at work creating a custom palette that blends your individual tastes
                into a cohesive palette that will color you both happy. Keep an eye on your email for your results!
              </p>
              <NavLink to='/' className='btn btn-dark'>
                BROWSE OUR COLOR COLLECTIONS
              </NavLink>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ThankYou;
