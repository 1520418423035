import React from 'react';
import { NavLink } from 'react-router-dom';
import cn from 'classnames';

const Banner = ({ title = 'Color without compromise', description = 'COLOR THERAPY QUIZ', link = null }) => {
  return (
    <div
      className={cn('banner', {
        'text-start': link
      })}>
      {title && <h2 className='title'>{title}</h2>}
      {description && <h1 className='description'>{description}</h1>}
      {link && (
        <NavLink to={link?.url} className='main-button'>
          {link?.text}
        </NavLink>
      )}
    </div>
  );
};

export default Banner;
