import React from 'react';
import { Row, Col } from 'react-bootstrap';
import coupleMedia from 'assets/img/DURADunnEdwards262.png';

const Coupon = ({ type, title, promo }) => {
  const getRightBlockStyles = () => {
    return {
      backgroundImage: `url(${coupleMedia})`
    };
  };

  return (
    <Row className='coupon w-100 px-0 mx-0'>
      <Col md={6} className='left pr-0'>
        {type && <h1 className='type' dangerouslySetInnerHTML={{ __html: `${type}` }} />}
        {title && <h2 className='title' dangerouslySetInnerHTML={{ __html: `${title}` }} />}
        {promo && <p className='promo' dangerouslySetInnerHTML={{ __html: `${promo}` }} />}
      </Col>
      <Col md={6} className='right px-0' style={getRightBlockStyles()} />
    </Row>
  );
};

export default Coupon;
