import React, { useContext, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Spinner from 'react-bootstrap/Spinner';
import { QuestionContext } from 'context/providers/QuestionProvider';
import { setIframeHeight } from 'helpers/utils';
import EmailForm from 'components/EmailForm';
import coupleMedia from 'assets/img/partnersEmail/shutterstock_1434688238.png';

const PartnersEmail = () => {
  const { loading, setLoading, getQuizzStatus } = useContext(QuestionContext);
  const history = useNavigate();

  useEffect(() => {
    setLoading(true);
    async function validate() {
      let quizzStatus = await getQuizzStatus();
      if(quizzStatus === 1) {
        history('/result')
      }
      setLoading(false);
    }
    validate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      className='partners-email'
      onLoad={() => {
        setIframeHeight();
      }}>
      {loading && (
        <div className='loader'>
          <Spinner animation='border' />
        </div>
      )}
      <Row className='status-bar w-100 px-0 mx-0' />
      <Container className='partners-email-content'>
        <Row>
          <Col xs={12} md={6} className='px-0'>
            <LazyLoadImage src={coupleMedia} alt='couple' className='img-fluid' />
          </Col>
          <Col xs={12} xl={5} md={6} className='px-0'>
            <EmailForm />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default PartnersEmail;
