import axios from 'axios';
const API_PATH = process.env.REACT_APP_BASE_API;

export const API_TOKEN = process.env.REACT_APP_TOKEN;

export const get = (apiEndpoint, params) => {
  return axios.get(API_PATH + apiEndpoint, { params }).then(response => {
    return response;
  });
};

export const post = (apiEndpoint, data) => {
  return axios
    .post(API_PATH + apiEndpoint + `?token=${API_TOKEN}`, {
      ...data
    })
    .then(response => {
      return response;
    });
};
