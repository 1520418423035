import React, { useContext } from 'react';
import { NavLink } from 'react-router-dom';
import { Container, Row } from 'react-bootstrap';
import { QuestionContext } from 'context/providers/QuestionProvider';
import { setIframeHeight, getResultPalette } from 'helpers/utils';
import Coupon from 'components/Coupon';
import Pallet from 'components/Pallet';
import Banner from 'components/Banner';
import unmatchData from './unmatchData';
import matchData from './matchData';

const Result = () => {
  const { results } = useContext(QuestionContext);
  let palette;

  if (results?.length > 0) {
    const { userA, userB } = results[0];
    palette = getResultPalette(userA, userB, matchData.palettes);
  }

  const { coupon, title, description, cta } = palette ? matchData : unmatchData;

  return (
    <>
      <Banner title='Color without compromise' description='COLOR THERAPY QUIZ' />
      <div className='result' onLoad={() => setIframeHeight()}>
        <Row className='status-bar w-100 px-0 mx-0' />
        <Container className='result-content'>
          {title && <h1 className='result-content-title'>{title}</h1>}
          {description && <p className='result-content-description'>{description}</p>}
          {palette && <Pallet {...palette} />}
          {coupon && <Coupon {...coupon} />}
          {cta && (
            <div className='result-content-cta'>
              <NavLink to={cta?.link} className='main-button'>
                {cta?.text}
              </NavLink>
            </div>
          )}
        </Container>
      </div>
    </>
  );
};

export default Result;
