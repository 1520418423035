import React, { useContext, useState } from 'react';
import { Nav } from 'react-bootstrap';
import Spinner from 'react-bootstrap/Spinner';
import cn from 'classnames';
import { QuestionContext } from 'context/providers/QuestionProvider';
import { useEffect } from 'react';

const StepsNavigation = () => {
  const { form, questions, selectedQuestions, isMobile, activeQuestion } = useContext(QuestionContext);
  const [showPopUp, setShowPopUp] = useState();
  const popUpMedia = questions[showPopUp]?.items?.find(item => item?.title === form?.[showPopUp]?.['answer'])?.media;
  const [popUpIsLoading, setPopUpIsLoading] = useState(true);

  useEffect(() => {
    if (showPopUp) {
      setPopUpIsLoading(true);
    }
  }, [showPopUp]);

  return (
    <Nav className='steps-navigation'>
      {questions?.map((item, index) => {
        return (
          <Nav.Item
            className={cn({
              selected: selectedQuestions?.includes(index) || activeQuestion?.id === index,
              active: activeQuestion?.id === index
            })}
            key={index}>
            <Nav.Link href='#' onMouseOver={() => setShowPopUp(index)} onMouseLeave={() => setShowPopUp()}>
              {selectedQuestions?.includes(index) && showPopUp === index && !isMobile && (
                <div
                  className={cn('pop-up', {
                    right: index > 8
                  })}>
                  <div className='pop-up-title'>{form[showPopUp]?.[`answer`]}</div>
                  <div className='pop-up-content'>
                    {popUpIsLoading && (
                      <div className='pop-up-loader'>
                        <Spinner animation='border' />
                      </div>
                    )}
                    <img
                      src={popUpMedia?.src}
                      alt={popUpMedia?.alt}
                      onLoad={() => {
                        setPopUpIsLoading(false);
                      }}
                    />
                  </div>
                </div>
              )}
              {index + 1}
            </Nav.Link>
          </Nav.Item>
        );
      })}
    </Nav>
  );
};

export default StepsNavigation;
