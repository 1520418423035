import React from 'react';
import { Col } from 'react-bootstrap';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import PalletColors from './PalletColors';

const Pallet = ({ media, colors, title, copy }) => (
  <Col md={12} className='component-wrapper'>
    <div className='pallet mb-3 single-item'>
      <div className='pallet-content'>
        <div className='text'>
          {title && <h1 className='title'>{title}</h1>}
          {copy && <p className='description'>{copy}</p>}
        </div>
        {media && (
          <div className='media'>
            <LazyLoadImage src={media?.source} alt={media?.alt} className='main' />
          </div>
        )}
        {colors && <PalletColors colors={colors} />}
      </div>
    </div>
  </Col>
);

export default Pallet;
