export const verifyEmail = email => {
  if (/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(email)) return true;
  return false;
};

export const setIframeHeight = () => {
  let html = document.documentElement;
  const message = JSON.stringify({ height: html.offsetHeight });
  window.parent.postMessage(message, '*');
};

export const updateIframeParentLocation = () => {
  const message = JSON.stringify({ url: '/' });
  window.parent.postMessage(message, '*');
};

export const getCtaBackground = color => {
  if (color?.hex && color?.hex !== '#') {
    return { background: color.hex };
  }
  return { background: '#000000' };
};

export const getResultPalette = (fp, sp, rp) => {
  const fp1 = fp.toLowerCase();
  const sp1 = sp.toLowerCase();
  if (rp[`${fp1}+${sp1}`]) {
    return rp[`${fp1}+${sp1}`];
  } else if (rp[`${sp1}+${fp1}`]) {
    return rp[`${sp1}+${fp1}`];
  }
  return false;
};
