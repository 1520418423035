import React, { useEffect, useContext } from 'react';
import Spinner from 'react-bootstrap/Spinner';
import { QuestionContext } from 'context/providers/QuestionProvider';
import { setIframeHeight } from 'helpers/utils';
import TwoBlocks from 'components/TwoBlocks';
import data from './data';

const Intro = () => {
  const { loading, setLoading } = useContext(QuestionContext);

  useEffect(() => {
    setLoading(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      className='intro'
      onLoad={() => {
        setIframeHeight();
        setLoading(false);
      }}>
      {loading && (
        <div className='loader'>
          <Spinner animation='border' />
        </div>
      )}
      {data?.map((item, index) => {
        return <TwoBlocks key={index} {...item} />;
      })}
    </div>
  );
};

export default Intro;
