import React, { useContext } from 'react';
import { Container, Row } from 'react-bootstrap';
import { QuestionContext } from 'context/providers/QuestionProvider';
import QuestionsListItem from './QuestionsListItem';

const QuestionsList = () => {
  const { activeQuestion } = useContext(QuestionContext);

  return (
    <Container className='questions-list'>
      <Row className='pt-5'>
        {activeQuestion?.items?.map(item => {
          return <QuestionsListItem item={item} key={item.id} />;
        })}
      </Row>
    </Container>
  );
};

export default QuestionsList;
