import React, { useContext, useState, useEffect } from 'react';
import { Col, Spinner } from 'react-bootstrap';
import cn from 'classnames';
import { QuestionContext } from 'context/providers/QuestionProvider';

const QuestionListItem = ({ item }) => {
  const { form, setForm, activeQuestion } = useContext(QuestionContext);
  const { title, media, pallet } = item;
  const index = activeQuestion?.id;
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeQuestion]);

  const handleSetForm = (title, pallet) => {
    const response = {
      id: `question${index + 1}`,
      label: activeQuestion?.title,
      pallet: pallet ? pallet : '',
      answer: title
    };
    setForm(response, index);
  };

  return (
    <Col
      md={4}
      xs={12}
      className={cn('item px-0 pb-md-5', {
        selected: form[index]?.['answer'] === title && media,
        'my-3': !media
      })}
      onClick={() => {
        if (!isLoading) {
          handleSetForm(title, pallet);
        }
      }}>
      {media && (
        <div className='group'>
          {isLoading && (
            <div className='loader'>
              <Spinner animation='border' />
            </div>
          )}
          <img
            src={media?.src}
            alt={media?.alt}
            className='img-fluid image'
            onLoad={() => {
              setIsLoading(false);
            }}
          />
        </div>
      )}
      {title && (
        <h3
          className={cn('title', {
            selected: form[index]?.[`question${index + 1}`]?.answer === title && !media,
            border: !media
          })}>
          {title}
        </h3>
      )}
    </Col>
  );
};

export default QuestionListItem;
