import React from 'react';
import ReactDOM from 'react-dom/client';
import { HashRouter } from 'react-router-dom';
import { Provider } from 'context/Provider';
import App from './App';
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/sass/styles.scss';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <HashRouter>
    <Provider>
      <App />
    </Provider>
  </HashRouter>
);
