import React from 'react';
import { setIframeHeight } from 'helpers/utils';
import QuestionList from 'components/QuestionsList';
import StepsNavigation from 'components/Steps/StepsNavigation';
import StepsPanel from 'components/Steps/StepsPanel';

const Question = () => {
  return (
    <div
      className='question'
      onLoad={() => {
        setIframeHeight();
      }}>
      <StepsNavigation />
      <StepsPanel />
      <QuestionList />
    </div>
  );
};

export default Question;
