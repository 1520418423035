import React, { useState, useContext } from 'react';
import { Form, Button } from 'react-bootstrap';
import TagManager from 'react-gtm-module';
import { QuestionContext } from 'context/providers/QuestionProvider';
import { verifyEmail } from 'helpers/utils';

const EmailForm = () => {
  const [emailForm, setEmailForm] = useState({});
  const [emailFormErrors, setEmailFormError] = useState({ userAEmail: false, userBEmail: false });
  const { registerEmails } = useContext(QuestionContext);

  const handleChange = (name, value) => {
    setEmailForm(form => {
      return { ...form, [name]: value !== '' ? value : null };
    });
  };

  const handleSubmit = async e => {
    e.preventDefault();
    let errors = { userAEmail: false, userBEmail: false };

    // userA form data
    if (!emailForm.userAFirstName) {
      errors = { ...errors, userAFirstName: 'First name field is required.' };
    }

    if (!emailForm.userALastName) {
      errors = { ...errors, userALastName: 'Last name field is required.' };
    }

    if (!emailForm.userAEmail) {
      errors = { ...errors, userAEmail: 'Email field is required.' };
    } else if (!verifyEmail(emailForm.userAEmail)) {
      errors = { ...errors, userAEmail: 'Invalid email address.' };
    }

    // userB form data
    if (!emailForm.userBFirstName) {
      errors = { ...errors, userBFirstName: 'Partner’s first name field is required.' };
    }

    if (!emailForm.userBLastName) {
      errors = { ...errors, userBLastName: 'Partner’s last name field is required.' };
    }

    if (!emailForm.userBEmail) {
      errors = { ...errors, userBEmail: 'Partner’s email field is required.' };
    } else if (!verifyEmail(emailForm.userBEmail)) {
      errors = { ...errors, userBEmail: 'Invalid email address.' };
    }

    setEmailFormError(errors);

    if (!errors.userAEmail && !errors.userBEmail) {
      const tagManagerArgs = {
        gtmId: 'GTM-NF2GXBN',
        dataLayer: {
          event: 'quiz_complete'
        }
      };

      await registerEmails(emailForm);
      setEmailForm({});
      TagManager.dataLayer(tagManagerArgs);
    }
  };

  return (
    <div className='email-form'>
      <h1 className='title'>Complete your color story</h1>
      <p className='description'>
        Thanks for sharing your color preferences. Now let’s hear what the other half of your color equation has to say.
        Add their email address below and we’ll send them the same quiz to complete.
      </p>
      <Form onSubmit={handleSubmit}>
        <Form.Group className='form-group'>
          <Form.Control
            placeholder='Your first name:'
            name='userAFirstName'
            className={emailFormErrors?.userAFirstName && 'error'}
            value={emailForm?.userAFirstName ? emailForm?.userAFirstName : ''}
            onChange={e => handleChange('userAFirstName', e.target.value)}
          />
          {emailFormErrors?.userAFirstName && <div className='error-message'>{emailFormErrors?.userAFirstName}</div>}
        </Form.Group>
        <Form.Group className='form-group'>
          <Form.Control
            placeholder='Your last name:'
            name='userALastName'
            className={emailFormErrors?.userALastName && 'error'}
            value={emailForm?.userALastName ? emailForm?.userALastName : ''}
            onChange={e => handleChange('userALastName', e.target.value)}
          />
          {emailFormErrors?.userALastName && <div className='error-message'>{emailFormErrors?.userALastName}</div>}
        </Form.Group>
        <Form.Group className='form-group'>
          <Form.Control
            placeholder='Your email:'
            name='userAEmail'
            className={emailFormErrors?.userAEmail && 'error'}
            value={emailForm?.userAEmail ? emailForm?.userAEmail : ''}
            onChange={e => handleChange('userAEmail', e.target.value)}
          />
          {emailFormErrors?.userAEmail && <div className='error-message'>{emailFormErrors?.userAEmail}</div>}
        </Form.Group>
        <Form.Group className='form-group'>
          <Form.Control
            placeholder='Your color partner’s first name:'
            name='userBFirstName'
            className={emailFormErrors?.userBFirstName && 'error'}
            value={emailForm?.userBFirstName ? emailForm?.userBFirstName : ''}
            onChange={e => handleChange('userBFirstName', e.target.value)}
          />
          {emailFormErrors?.userBFirstName && <div className='error-message'>{emailFormErrors?.userBFirstName}</div>}
        </Form.Group>
        <Form.Group className='form-group'>
          <Form.Control
            placeholder='Your color partner’s last name:'
            name='userBLastName'
            className={emailFormErrors?.userBLastName && 'error'}
            value={emailForm?.userBLastName ? emailForm?.userBLastName : ''}
            onChange={e => handleChange('userBLastName', e.target.value)}
          />
          {emailFormErrors?.userBLastName && <div className='error-message'>{emailFormErrors?.userBLastName}</div>}
        </Form.Group>
        <Form.Group className='form-group'>
          <Form.Control
            placeholder='Your color partner’s email:'
            name='userBEmail'
            className={emailFormErrors?.userBEmail && 'error'}
            value={emailForm?.userBEmail ? emailForm?.userBEmail : ''}
            onChange={e => handleChange('userBEmail', e.target.value)}
          />
          {emailFormErrors?.userBEmail && <div className='error-message'>{emailFormErrors?.userBEmail}</div>}
        </Form.Group>
        <Form.Group className='mb-3 terms-and-conditions' controlId='termsAndConditions'>
          <Form.Check type='checkbox' name='termsAndConditions' required />
          <Form.Label>
            I agree to the{' '}
            <a href='https://dunnedwardsdura.com/pages/legal-documentation' rel='noreferrer' target='_blank'>
              Terms of Use
            </a>{' '}
            and{' '}
            <a href='https://dunnedwardsdura.com/pages/legal-documentation' rel='noreferrer' target='_blank'>
              Privacy Policy
            </a>
          </Form.Label>
        </Form.Group>
        <Button variant='dark' size='lg' type='submit' className='main-button'>
          SEND QUIZ NOW
        </Button>
      </Form>
    </div>
  );
};

export default EmailForm;
