import React, { createContext, useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { setIframeHeight, updateIframeParentLocation } from 'helpers/utils';
import questionsData from 'containers/Question/questions';
import * as api from 'context/api';

const contextDefaultValues = {
  questions: questionsData,
  form: [],
  selectedQuestions: [],
  quizzId: '',
  activeQuestion: {},
  isMobile: false,
  loading: false,
  results: [],
  setForm: () => {},
  setActiveQuestion: () => {},
  setNextPrevQuestion: () => {},
  setLoading: () => {}
};

export const QuestionContext = createContext(contextDefaultValues);

const QuestionProvider = ({ children }) => {
  const [form, setFormCtx] = useState(contextDefaultValues.form);
  const [selectedQuestions, setSelectedQuestionsCtx] = useState(contextDefaultValues.selectedQuestions);
  const [activeQuestion, setActiveQuestionCtx] = useState(contextDefaultValues.activeQuestion);
  const [isMobile, setIsMobileCtx] = useState(contextDefaultValues.isMobile);
  const [quizzId, setQuizzIdCtx] = useState(contextDefaultValues.quizzId);
  const [loading, setLoadingCtx] = useState(contextDefaultValues.loading);
  const [results, setResults] = useState(contextDefaultValues.results);
  const [token, setToken] = useState('temp');
  const history = useNavigate();
  const location = useLocation();
  const { questions } = contextDefaultValues;

  useEffect(() => {
    setDefaultContextData();
    if (window.innerWidth < 768) {
      setIsMobileCtx(true);
    }
    const handleResize = () => {
      setIsMobileCtx(window.innerWidth < 768 ? true : false);
    };
    window.addEventListener('resize', () => {
      handleResize();
      setIframeHeight();
    });
    window.addEventListener('message', e => {
      const data = JSON.parse(JSON.stringify(e?.data));
      if (data?.quizzId) {
        const { quizzId } = data;
        if (quizzId?.length > 0 && quizzId !== 'null' && JSON.parse(localStorage.getItem('quizzId')) !== quizzId) {
          clearData();
          localStorage.setItem('quizzId', JSON.stringify(quizzId));
          setDefaultContextData();
          validateQuizzId(quizzId);
        } else if (quizzId === 'null') {
          localStorage.removeItem('quizzId');
          clearData();
          setDefaultContextData();
        }
      }
    });
    var iFrameDetection = window === window.parent ? false : true;
    if (!iFrameDetection) {
      clearData();
      const searchQuizzId = window.location?.hash.split('=').pop();
      if (searchQuizzId) {
        localStorage.setItem('quizzId', JSON.stringify(searchQuizzId));
        setDefaultContextData();
        validateQuizzId(quizzId);
      }
    }
    return () => {
      window.removeEventListener('resize', () => {});
      window.removeEventListener('message', () => {});
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (window.location !== window.parent.location) {
      // The app is in an iframe
      setIframeHeight();
    } else {
      // The app is not in an iframe
      const search = window?.location?.href;
      if (search?.includes('quizId')) {
        let quizzId = search?.split('quizId=').pop();
        if (quizzId?.length > 0 && JSON.parse(localStorage.getItem('quizzId')) !== quizzId) {
          clearData();
          localStorage.setItem('quizzId', JSON.stringify(quizzId));
          setDefaultContextData();
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const setDefaultContextData = () => {
    let itemTitle = 'temp';
    let id;
    if (localStorage.getItem('quizzId')) {
      id = JSON.parse(localStorage.getItem('quizzId'));
      itemTitle = `token_${id}`;
      setQuizzIdCtx(id);
      setToken(`token_${id}`);
    } else {
      setToken('temp');
    }

    setActiveQuestionCtx(questionsData[0]);

    if (localStorage.getItem(itemTitle)) {
      const data = JSON.parse(localStorage.getItem(itemTitle));
      const { form, selectedQuestions, activeQuestion } = data;
      form && setFormCtx(form);
      if (selectedQuestions) {
        setSelectedQuestionsCtx(selectedQuestions);
      }
      activeQuestion && setActiveQuestionCtx(activeQuestion);
    }
  };

  const setForm = (response, index) => {
    let item;

    if (!form[index] || (form[index] && form[index]?.['answer'] !== response?.answer)) {
      item = response;
    }
    setFormCtx(prevForm => {
      let newForm = prevForm;
      if (item) {
        if (!selectedQuestions.includes(activeQuestion.id)) {
          setSelectedQuestions(activeQuestion.id);
        }
        newForm[index] = item;
      }
      setActiveToken('form', [...newForm]);
      return [...newForm];
    });
  };

  const setSelectedQuestions = newQuestion => {
    setSelectedQuestionsCtx(prevQuestion => {
      setActiveToken('selectedQuestions', [...prevQuestion, newQuestion]);
      return [...prevQuestion, newQuestion];
    });
  };

  const setActiveQuestion = newActiveQuestion => {
    setActiveToken('activeQuestion', questions[newActiveQuestion]);
    setActiveQuestionCtx(questions[newActiveQuestion]);
  };

  const setNextPrevQuestion = async direction => {
    if (direction === 'next') {
      if (activeQuestion.id + 1 < questions.length) {
        setActiveToken('activeQuestion', questions[activeQuestion.id + 1]);
        setActiveQuestionCtx(questions[activeQuestion.id + 1]);
      } else {
        if (selectedQuestions.length === questions.length) {
          await validateQuizzId();
          submitResponses();
        }
      }
    } else {
      if (activeQuestion.id - 1 >= 0) {
        setActiveToken('activeQuestion', questions[activeQuestion.id - 1]);
        setActiveQuestionCtx(questions[activeQuestion.id - 1]);
      } else {
        history('/');
      }
    }
  };

  const validateQuizzId = async (id = null) => {
    setLoadingCtx(true);
    let idToValidate = id;

    if (!id) {
      if (window.location !== window.parent.location) {
        if (localStorage.getItem('quizzId') && !quizzId) {
          idToValidate = JSON.parse(localStorage.getItem('quizzId'));
        } else if (quizzId) {
          idToValidate = quizzId;
        }
      } else {
        const search = window?.location?.href;
        if (search.includes('quizzId')) {
          idToValidate = parseInt(search?.split('quizzId=').pop());
        }
      }
    }

    if (idToValidate) {
      const quizzStatus = await getQuizzStatus(idToValidate);
      checkStatus(quizzStatus);
    } else {
      setLoadingCtx(false);
    }
  };

  const checkStatus = status => {
    switch (status) {
      case 1:
        clearData();
        localStorage.removeItem('temp');
        history('/result');
        setLoadingCtx(false);
        break;
      case 2:
        history(`/question`);
        setLoadingCtx(false);
        break;
      case 3:
        history(`/partners-email`);
        setLoadingCtx(false);
        break;
      default:
        updateIframeParentLocation();
        localStorage.removeItem('quizzId');
        history('/');
        break;
    }
  };

  const submitResponses = async () => {
    setLoadingCtx(true);

    const data = {
      quizzId: quizzId,
      questions: [...form]
    };

    await api
      .post('/record-quizz', data)
      .then(res => {
        if (res?.data?.quizzId) {
          history('/partners-email');
          setQuizzIdCtx(res?.data?.quizzId);
          localStorage.setItem('quizzId', res?.data?.quizzId);
        }
      })
      .catch(error => {
        console.log('Record quizz failed Error: ', error);
        clearData();
        history('/');
        setLoadingCtx(false);
      });
  };

  const registerEmails = async form => {
    setLoadingCtx(true);

    const userA = {
      email: form.userAEmail,
      firstName: form.userAFirstName,
      lastName: form.userALastName
    };
    const userB = {
      email: form.userBEmail,
      firstName: form.userBFirstName,
      lastName: form.userBLastName
    };
    const data = {
      quizzId: parseInt(quizzId),
      emails: [{ userA }, { userB }]
    };

    await api
      .post('/register-emails', data)
      .then(res => {
        if (res?.data?.statusCode === 200) {
          history('/thank-you');
          clearData();
        }
        setLoadingCtx(false);
      })
      .catch(error => {
        history('/');
        setLoadingCtx(false);
      });

    localStorage.removeItem('temp');
  };

  const setActiveToken = (name, value) => {
    let data = JSON.parse(localStorage.getItem(token));
    localStorage.setItem(token, JSON.stringify({ ...data, [name]: value }));
  };

  const setLoading = value => {
    setLoadingCtx(value);
  };

  const clearData = () => {
    setFormCtx([]);
    setSelectedQuestionsCtx([]);
    setActiveQuestionCtx(questionsData[0]);
    setQuizzIdCtx('');
    setToken('temp');
    localStorage.removeItem('quizzId');
    localStorage.removeItem('activeQuestion');
    localStorage.removeItem('form');
    localStorage.removeItem('selectedQuestions');
  };

  const getQuizzStatus = async (id = quizzId) => {
    let quizzStatus = 0;
    await api
      .get(`/validate-quizz`, { token: api.API_TOKEN, quizzId: id })
      .then(res => {
        const { status, statusCode } = res.data;
        if (statusCode === 200 && status) {
          quizzStatus = status;
          if (status === 1) {
            setResults(res?.data?.results);
          } else {
            setResults([]);
          }
        }
      })
      .catch(err => {
        console.log('Validate Quizz failed. Error: ', err);
        setLoadingCtx(false);
      });
    return quizzStatus;
  };

  return (
    <QuestionContext.Provider
      value={{
        form,
        questions,
        selectedQuestions,
        activeQuestion,
        isMobile,
        quizzId,
        loading,
        results,
        setForm,
        setActiveQuestion,
        setNextPrevQuestion,
        validateQuizzId,
        clearData,
        registerEmails,
        setLoading,
        getQuizzStatus
      }}>
      {children}
    </QuestionContext.Provider>
  );
};

export default QuestionProvider;
